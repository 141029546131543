import { isValidFunction, isValidString, tr } from 'mmfintech-commons'

import { useOnboarding } from '../../../hooks'
import { useGetMerchantQuery } from 'mmfintech-backend-api'

import { BannerContent, BannerTitle, BannerWrapper } from './OnboardingBanner.styled'

import { MerchantAccountTypeEnum, OnboardingProviderEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'

import ArrowIcon from '../../../images/icons/arrow.svg?react'
import BellIcon from '../../../images/icons/bell-icon.svg?react'

export const OnboardingBanner = ({ className = '' }) => {
  const { data: merchant } = useGetMerchantQuery()

  const { accountType, onboardingProvider, onboardingStatus } = merchant || {}

  const { startOrContinueOnboarding } = useOnboarding()

  const Banner = ({ title = '', content, handleClick = null }) => {
    return (
      <BannerWrapper className={className} onClick={handleClick}>
        <div className='left'>
          <BellIcon />
          <BannerContent>
            {isValidString(title) && <BannerTitle>{title}</BannerTitle>}
            {content}
          </BannerContent>
        </div>
        {isValidFunction(handleClick) && <ArrowIcon className='arrow-icon' onClick={handleClick} />}
      </BannerWrapper>
    )
  }

  if (onboardingProvider === OnboardingProviderEnum.SUMSUB) {
    if (onboardingStatus === OnboardingStatusEnum.APPROVED_EXPIRING_DOCUMENT) {
      return (
        <Banner
          title={tr('FRONTEND.ONBOARDING.EXPIRING_DOCUMENT.HEADING', 'Expiring document')}
          content={tr(
            'FRONTEND.ONBOARDING.EXPIRING_DOCUMENT.MESSAGE',
            'Your document is expiring, upload newer one to avoid limitation of services.'
          )}
          handleClick={() => startOrContinueOnboarding()}
        />
      )
    }

    if (accountType === MerchantAccountTypeEnum.PROSPECT) {
      if (
        onboardingStatus === OnboardingStatusEnum.NOT_STARTED ||
        onboardingStatus === OnboardingStatusEnum.IN_PROGRESS ||
        onboardingStatus === OnboardingStatusEnum.REJECTED_RETRY
      ) {
        return (
          <Banner
            title={tr('FRONTEND.ONBOARDING.COMPLETE_TITLE', 'Complete your account')}
            content={tr(
              'FRONTEND.ONBOARDING.COMPLETE_TEXT',
              'Verify to get access to all features and increased payment limits.'
            )}
            handleClick={() => startOrContinueOnboarding()}
          />
        )
      }

      if (onboardingStatus === OnboardingStatusEnum.WAITING_QUESTIONNAIRE) {
        return (
          <Banner
            title={tr('FRONTEND.ONBOARDING.QUESTIONNAIRE.HEADING', 'Complete the questionnaire')}
            content={tr(
              'FRONTEND.ONBOARDING.QUESTIONNAIRE.MESSAGE',
              'Complete the questionnaire in order to proceed with the account onboarding'
            )}
            handleClick={() => startOrContinueOnboarding()}
          />
        )
      }

      if (
        onboardingStatus === OnboardingStatusEnum.UNDER_REVIEW ||
        onboardingStatus === OnboardingStatusEnum.WAITING_COMPLIANCE_APPROVAL ||
        onboardingStatus === OnboardingStatusEnum.WAITING_OPS_APPROVAL
      ) {
        return (
          <Banner
            content={tr('FRONTEND.ONBOARDING.UNDER_REVIEW_MESSAGE', 'Your account verification is under review.')}
          />
        )
      }

      if (onboardingStatus === OnboardingStatusEnum.REJECTED_FINAL) {
        return (
          <Banner
            content={tr(
              'FRONTEND.ONBOARDING.REJECTED_MESSAGE',
              'Please contact Customer Service to get support on your account verification.'
            )}
          />
        )
      }
    }
  }

  return null
}
