import React from 'react'
import { Link } from 'react-router-dom'

import { tr } from 'mmfintech-commons'
import { paths, useLoginQry } from 'mmfintech-backend-api'

import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import { BankyLogo, LanguageMenu, LanguageSelectWrapper } from '../../../components'
import { LoginContainer, LoginContent, LoginForm, LoginLink, LoginSignup, LoginWrapper } from './Login.styled'

const Login = () => {
  const { formValues, login, loginError, loginFetching, resetLogin } = useLoginQry()

  const handleSubmit = async (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    void login()
  }

  return (
    <LoginWrapper>
      <LoginContainer>
        <LanguageSelectWrapper>
          <LanguageMenu bordered />
        </LanguageSelectWrapper>

        <BankyLogo />

        <LoginContent>
          <div className='title'>{tr('FRONTEND.LOGIN.TITLE', 'Sign in to your account')}</div>

          <LoginForm noValidate onSubmit={handleSubmit}>
            <Input
              type='email'
              data-test='email'
              label={tr('FRONTEND.LOGIN.EMAIL', 'Email')}
              {...formValues.registerInput('email', resetLogin)}
              required={true}
              hideRequired={false}
              requiredPosition='right'
              disabled={loginFetching}
              autoFocus
            />

            <Input
              type='password'
              data-test='password'
              label={tr('FRONTEND.LOGIN.PASSWORD', 'Password')}
              {...formValues.registerInput('password', resetLogin)}
              required={true}
              hideRequired={false}
              requiredPosition='right'
              disabled={loginFetching}
              autoComplete='off'
              hideErrorLine
            />

            <LoginLink data-test='forgot-password'>
              <Link to={paths.forgotPassword()}>{tr('FRONTEND.LOGIN.FORGOT_PASSWORD', 'Forgot password?')}</Link>
            </LoginLink>

            <ErrorDisplay error={loginError} />

            <Button
              type='submit'
              color='primary'
              text={tr('FRONTEND.LOGIN.BUTTON_LOGIN', 'Sign in')}
              loading={loginFetching}
              data-test='button-submit'
            />

            <LoginSignup>
              {tr('FRONTEND.LOGIN.NO_ACCOUNT', "Don't have an account?")}{' '}
              <Link to={paths.signUp()} data-test='sign-up'>
                <span>{tr('FRONTEND.LOGIN.SIGN_UP_FREE', 'Sign up for FREE')}</span>
              </Link>
            </LoginSignup>
          </LoginForm>
        </LoginContent>
      </LoginContainer>
    </LoginWrapper>
  )
}

export default Login
