import React, { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'

import { tr, useWindowSize } from 'mmfintech-commons'
import {
  isOwnerOrAdministrator,
  paths,
  selectCurrentUserRole,
  useAppSelector,
  useAuth,
  useGetMerchantQuery,
  useLogoutQry
} from 'mmfintech-backend-api'

import { HeaderIcons } from './HeaderIcons'
import { LanguageMenu } from '../LanguageMenu'
import { MerchantSwitchMenu } from '../MerchantSwitchMenu'
import {
  BrandLogoWrapper,
  Hamburger,
  HamburgerIcon,
  HamburgerMenu,
  HeaderContainer,
  HeaderInnerContainer,
  HeaderMenuWrapper,
  HeaderWrapper
} from './Header.styled'

import settings from '../../settings'

import brandLogo from '../../images/banky-logo.png'

import { LoginStatusEnum } from 'mmfintech-commons-types'

const MAX_MOBILE_WIDTH = 1150

export const Header = () => {
  const [opened, setOpened] = useState(false)

  const userStatus = useAuth()
  const customerRole = useAppSelector(selectCurrentUserRole)

  const { data: merchant } = useGetMerchantQuery()
  const { apiEnabled, /* accountType, */ forexEnabled } = merchant || {}

  const [width] = useWindowSize()
  const { logout } = useLogoutQry()

  const isLoggedIn = () => userStatus === LoginStatusEnum.LOGGED_IN

  // const isAccountMenuVisible = () => {
  //   return accountType && accountType !== MerchantAccountTypeEnum.PROSPECT
  // }

  const isDevelopersMenuVisible = () => !!apiEnabled && isOwnerOrAdministrator(customerRole)
  const isForexMenuVisible = () => forexEnabled && isOwnerOrAdministrator(customerRole)
  const isHamburgerVisible = () => width < MAX_MOBILE_WIDTH

  const hideMenu = () => opened && setOpened(false)

  const handleLogoutClick = (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    hideMenu()
    void logout()
    return false
  }

  const BrandLogoImage = () => <img src={brandLogo} alt='Logo' style={{ maxHeight: '32px' }} />

  const BrandLogo = () => {
    return (
      <BrandLogoWrapper>
        {isLoggedIn() ? (
          <Link to={paths.dashboard()}>
            <BrandLogoImage />
          </Link>
        ) : (
          <a href={settings.landingPageUrl}>
            <BrandLogoImage />
          </a>
        )}
      </BrandLogoWrapper>
    )
  }

  const MainMenuItems = () => (
    <>
      <NavLink activeClassName='active-tab' to={paths.dashboard()} onClick={hideMenu}>
        {tr('FRONTEND.HEADER.OVERVIEW', 'Overview')}
      </NavLink>
      {/* {isAccountMenuVisible() && ( */}
      <NavLink activeClassName='active-tab' to={paths.banking.accounts.list()} onClick={hideMenu}>
        {tr('FRONTEND.HEADER.ACCOUNTS', 'Accounts')}
      </NavLink>
      {/* )} */}
      {/* {isAccountMenuVisible() && ( */}
      <NavLink activeClassName='active-tab' to={paths.banking.transactions.list()} onClick={hideMenu}>
        {tr('FRONTEND.HEADER.TRANSACTIONS', 'Transactions')}
      </NavLink>
      {/* )} */}
      <NavLink activeClassName='active-tab' to={paths.banking.expenses()} onClick={hideMenu}>
        {tr('FRONTEND.HEADER.EXPENSES', 'Expenses')}
      </NavLink>
      {isForexMenuVisible() && (
        <NavLink activeClassName='active-tab' to={paths.forex()} onClick={hideMenu}>
          {tr('FRONTEND.HEADER.FOREX', 'Forex')}
        </NavLink>
      )}
      {isOwnerOrAdministrator(customerRole) && (
        <NavLink activeClassName='active-tab' to={paths.userManagement()} onClick={hideMenu}>
          {tr('FRONTEND.HEADER.USERS', 'Users')}
        </NavLink>
      )}
      {isDevelopersMenuVisible() && (
        <NavLink activeClassName='active-tab' to={paths.developer()} onClick={hideMenu}>
          {tr('FRONTEND.HEADER.DEVELOPERS', 'Developers')}
        </NavLink>
      )}
    </>
  )

  const UserMenuItems = ({ className = '', onClick }) => (
    <>
      <NavLink to={paths.settings()} onClick={onClick} className={className}>
        {tr('FRONTEND.HEADER.SETTINGS', 'Settings')}
      </NavLink>
      <NavLink to={paths.externalAccounts()} onClick={onClick} className={className}>
        {tr('FRONTEND.HEADER.EXTERNAL_ACCOUNTS', 'External accounts')}
      </NavLink>
      <a
        href={settings.customerServiceUrl}
        rel='noopener noreferrer'
        target='_blank'
        onClick={onClick}
        className={className}>
        {tr('FRONTEND.HEADER.CUSTOMER_SERVICE', 'Customer service')}
      </a>
    </>
  )

  useEffect(() => {
    width > MAX_MOBILE_WIDTH && hideMenu()
    // eslint-disable-next-line
  }, [width])

  useEffect(() => {
    const body = document.getElementsByTagName('body').item(0)
    if (body) {
      if (opened) {
        body.classList.add('menu-opened')
      } else {
        body.classList.remove('menu-opened')
      }
    }
    // eslint-disable-next-line
  }, [opened])

  return (
    <HeaderWrapper>
      {opened && <div className='overlay' onClick={hideMenu} />}

      <HeaderContainer maxMobileWidth={MAX_MOBILE_WIDTH}>
        <BrandLogo />

        {!isHamburgerVisible() && isLoggedIn() ? (
          <HeaderMenuWrapper>
            <MainMenuItems />
          </HeaderMenuWrapper>
        ) : null}

        {isHamburgerVisible() ? (
          <HeaderInnerContainer>
            <Hamburger>
              <HamburgerIcon onClick={() => setOpened(prevState => !prevState)}>
                <span className='menu-icon' />
              </HamburgerIcon>
              <HamburgerMenu open={opened} maxMobileWidth={MAX_MOBILE_WIDTH}>
                <LanguageMenu hamburger showIcons />
                {isLoggedIn() && <MerchantSwitchMenu hamburger />}
                {isLoggedIn() && <MainMenuItems />}
                {isLoggedIn() && <UserMenuItems onClick={hideMenu} />}
                {isLoggedIn() && (
                  <NavLink to='#' onClick={handleLogoutClick}>
                    {tr('FRONTEND.HEADER.LOGOUT', 'Log out')}
                  </NavLink>
                )}
                {!isLoggedIn() && (
                  <NavLink to={paths.login()} onClick={hideMenu}>
                    {tr('FRONTEND.HEADER.LOGIN', 'Log in')}
                  </NavLink>
                )}
                {!isLoggedIn() && (
                  <NavLink to={paths.signUp()} onClick={hideMenu}>
                    {tr('FRONTEND.HEADER.SIGNUP', 'Sign Up Free')}
                  </NavLink>
                )}
              </HamburgerMenu>
            </Hamburger>
          </HeaderInnerContainer>
        ) : (
          <HeaderInnerContainer>
            {isLoggedIn() && <MerchantSwitchMenu />}
            {isLoggedIn() && <HeaderIcons UserMenu={UserMenuItems} maxWidth={MAX_MOBILE_WIDTH} />}
            <LanguageMenu showIcons />
          </HeaderInnerContainer>
        )}
      </HeaderContainer>
    </HeaderWrapper>
  )
}
