import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { useLogoutQry } from 'mmfintech-backend-api'
import { tr, useWindowSize } from 'mmfintech-commons'

import { HeaderIconsWrapper, DropDownMenuContainer } from './HeaderIcons.styled'

import SettingsIcon from '../../images/icons/settings-icon.svg?react'

interface HeaderIconsProps {
  UserMenu: React.FC<{ onClick: () => void; className: string }>
  maxWidth: number
}

export const HeaderIcons = ({ UserMenu, maxWidth }: HeaderIconsProps) => {
  const menuRef = useRef<HTMLInputElement>(null)

  const [visibleSettings, setVisibleSettings] = useState<boolean>(false)

  const [width] = useWindowSize()
  const { logout } = useLogoutQry()

  const toggleDropdown = () => {
    setVisibleSettings(prevState => !prevState)
  }

  const handleLogoutClick = (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    void logout()
    return false
  }

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setVisibleSettings(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
    // eslint-disable-next-line
  }, [visibleSettings])

  return (
    <HeaderIconsWrapper ref={menuRef}>
      <SettingsIcon data-test='settings-icon' onClick={() => toggleDropdown()} />

      {visibleSettings && (
        <DropDownMenuContainer className='settings'>
          <UserMenu
            onClick={() => {
              setVisibleSettings(false)
            }}
            className='menu-item selector'
          />
          {width >= maxWidth && (
            <NavLink to='#' className='menu-item selector' onClick={handleLogoutClick}>
              {tr('FRONTEND.HEADER.LOGOUT', 'Log Out')}
            </NavLink>
          )}
        </DropDownMenuContainer>
      )}
    </HeaderIconsWrapper>
  )
}
