import { useMerchantAccounts, useMyPermissionsQry } from 'mmfintech-backend-api'

import { Preloader } from 'mmfintech-portal-commons'
import { AccountsOverviewRow } from './AccountsOverviewRow'
import { AccountsOverviewStyled } from './AccountsOverview.styled'

import { AccountBalanceResponse } from 'mmfintech-commons-types'

export const AccountsOverview = () => {
  const { activeAccounts, accountsFetching } = useMerchantAccounts()
  const { permissions, permissionsFetching } = useMyPermissionsQry()

  if (accountsFetching || permissionsFetching) {
    return <Preloader />
  }

  const accounts = activeAccounts?.map((account: AccountBalanceResponse) => {
    const permission = permissions?.find((perm: any) => perm?.accountId === account.id)
    return { ...account, permission: permission?.accessLevel }
  })

  return (
    <AccountsOverviewStyled>
      {Array.isArray(accounts) &&
        accounts.map((account: any, index: number) => <AccountsOverviewRow account={account} key={index} />)}
    </AccountsOverviewStyled>
  )
}
