import { useHistory } from 'react-router-dom'

import { AccountButton } from './AccountButton'
import { ArrowRightIcon } from '../../../icons'
import { AccountDisplayBody, AccountsDisplayHeader, AccountsDisplayStyled } from './AccountsSection.styled'

import { tr } from 'mmfintech-commons'
import { paths } from 'mmfintech-backend-api'

import { AccountBalanceResponse } from 'mmfintech-commons-types'

interface AccountsSectionProps {
  accounts: AccountBalanceResponse[]
  onClick: (accountId: number) => void
}

export const AccountsSection = ({ accounts, onClick }: AccountsSectionProps) => {
  const history = useHistory()

  return (
    <AccountsDisplayStyled>
      <AccountsDisplayHeader>
        <div className='title'>{tr('FRONTEND.HEADER.ACCOUNTS', 'Accounts')}</div>
        <div className='view-all' onClick={() => history.push(paths.banking.accounts.list())}>
          {tr('FRONTEND.DASHBOARD.VIEW_ALL', 'View all')}
          <ArrowRightIcon />
        </div>
      </AccountsDisplayHeader>

      <AccountDisplayBody>
        {Array.isArray(accounts) &&
          accounts
            .slice(0, 5)
            .map((account, index) => <AccountButton key={`account-${index}`} account={account} onClick={onClick} />)}
      </AccountDisplayBody>
    </AccountsDisplayStyled>
  )
}
